import styled from 'styled-components';
import { size, margin } from '../../utils';

export const DateTimeStyled = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  width: 100%;
  ${size}
  ${margin}

  .react-datepicker {
    display: flex;
    position: relative;
    width: fit-content;
  }

  .react-datepicker:not(.--inline) {
    background-color: var(--color-surface-navbar);
    font-family: 'ProductSans';
    border-radius: 6px;
    box-shadow: 0px 4px 32px 0px var(--opacity-md);
  }

  .react-datepicker-popper {
    min-width: 200px;
    z-index: 1;
  }

  .react-datepicker__input-container {
    width: 100%;
    height: 35px;
    padding: 0 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-outline-border-default);
    border-radius: 0;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 100%;
    color: var(--color-text-secondary);
    background-color: transparent;
    border: 0 none;
  }

  .react-datepicker__input-container input::placeholder {
    color: var(--color-text-tertiary);
  }

  .react-datepicker__aria-live {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    white-space: nowrap;
    clip-path: circle(0);
  }

  .react-datepicker__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 12px;
    height: 12px;
    text-indent: -999em;
    border: none;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
  }

  .react-datepicker:not(.--inline) .react-datepicker__navigation {
    top: 24px;
  }

  .react-datepicker.--inline .react-datepicker__navigation {
    top: 0;
  }

  .react-datepicker:not(.--inline) .react-datepicker__navigation--previous {
    left: 18px;
  }

  .react-datepicker:not(.--inline) .react-datepicker__navigation--next {
    right: 18px;
  }

  .react-datepicker.--inline .react-datepicker__navigation--previous {
    left: 0;
  }

  .react-datepicker.--inline .react-datepicker__navigation--next {
    right: 0;
  }

  .react-datepicker:not(.--inline)
    .react-datepicker__navigation--next--with-time,
  .react-datepicker.--inline .react-datepicker__navigation--next--with-time {
    right: 122px;
  }

  .react-datepicker__navigation-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 12px;
    height: 12px;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
    }
  }

  .react-datepicker__navigation-icon--previous::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath fill='%239095A3' d='M7.765 9.985a.375.375 0 1 1-.53.53l-3.75-3.75a.375.375 0 0 1 0-.53l3.75-3.75a.375.375 0 0 1 .53.53L4.28 6.5l3.485 3.485Z'/%3E%3C/svg%3E");
  }

  .react-datepicker__navigation-icon--next::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath fill='%239095A3' d='m8.515 6.765-3.75 3.75a.375.375 0 0 1-.53-.53L7.72 6.5 4.235 3.015a.375.375 0 0 1 .53-.53l3.75 3.75a.375.375 0 0 1 0 .53Z'/%3E%3C/svg%3E");
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 268px;
    margin: 8px 0;
    padding: 16px;
  }

  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
  }

  .react-datepicker__current-month {
    color: var(--color-text-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .react-datepicker__header__dropdown {
    display: none;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
  }

  .react-datepicker__day-name {
    color: var(--color-text-link-disabled);
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .react-datepicker__day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: var(--color-text-primary);
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
  }

  .react-datepicker__day:not(.react-datepicker__day--selected):not(
      .react-datepicker__day--in-range
    ):not(.react-datepicker__day--in-selecting-range):hover {
    background-color: var(--color-surface-tag-grey);
  }

  .react-datepicker__day--today {
    font-size: 11px;
    font-weight: 700;
  }

  .react-datepicker__day--outside-month {
    color: var(--color-text-link-disabled);
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--selecting-range-start
    ):hover {
    color: #fff;
    background-color: var(--color-chart-data-2);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selecting-range-start {
    color: #fff;
    background-color: var(--color-surface-button-default);
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__input-time-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 12px 0;
  }

  .react-datepicker-time__caption {
    color: var(--color-text-tertiary);
    font-family: 'ProductSans';
    font-size: 10px;
    font-weight: 400;
  }

  .react-datepicker-time__input-container {
    flex-grow: 1;
  }

  /* Time */

  .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time-container {
    border-left: 1px solid var(--color-outline-border-default);
  }

  .react-datepicker__header--time {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--color-outline-border-default);
  }

  .react-datepicker-time__header {
    color: var(--color-text-primary);
    font-family: 'ProductSans';
    font-size: 12px;
    font-weight: 500;
  }

  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
  }

  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    height: 170px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 104px;
    margin: 0 auto;
    padding: 0 4px;
    text-align: center;
    overflow-x: hidden;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: 170px;
    overflow-y: scroll;
    padding: 8px 0;
    width: 100%;
    box-sizing: content-box;

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-outline-border-default);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding: 0;
    color: var(--color-text-primary);
    font-family: 'ProductSans';
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    color: var(--color-text-link-default);
    cursor: pointer;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    color: var(--color-text-link-default);
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    color: var(--color-text-link-default);
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: var(--color-text-link-disabled);
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    color: var(--color-text-link-disabled);
    cursor: default;
  }
`;

export const TimeInputStyled = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 8px;
  color: var(--color-text-tertiary);
  background-color: var(--color-surface-searchbar-light-default);
  font-family: 'ProductSans';
  font-size: 10px;
  font-weight: 500;
  border: 1px solid var(--color-outline-border-default);
  border-radius: 6px;
`;
