module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uplft™ Control Plane","short_name":"Uplft™","start_url":"/en-US","background_color":"#ffffff","theme_color":"#5a5a5a","display":"standalone","crossOrigin":"anonymous","icons":[{"src":"/assets/images/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/assets/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/assets/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/assets/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/assets/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/assets/images/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/assets/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/assets/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"include_favicon":false,"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-Y1Q71G3T64","head":false,"anonymize":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src4191993260/src/codestar-connections.us-east-1.amazonaws.com/git-http/729717711108/us-east-1/2abbd8b0-552d-41b9-85c2-991638472f7d/vcomedia/uplft/frontend/site-uplftbi/src/messages","languages":["en-US"],"defaultLanguage":"en-US","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
